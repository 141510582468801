<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="客户账户金额" name="first">
      <div class="feature-form-container">
        <el-form
          :inline="true"
          :model="dataForm"
          ref="dataForm"
          class="bysearchForm"
        >
          <div class="searchLine">
            <el-form-item label="时间" class="typeInput">
              <el-date-picker
                v-model="time"
                class="el-popper DatepickerTime"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item class="typeInput">
              <el-select
                v-model="dataForm.companyIds"
                placeholder="公司"
                filterable
                multiple
                clearable
              >
                <el-option
                  v-for="(item, index) in companyList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-button size="mini" @click="getDataList()">查询</el-button>
            <el-button
              @click="exportCustomerAccount()"
              size="small"
              type="primary"
              >导出客户账户金额报表</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="table-container">
        <el-table :data="dataList" ref="dataList" border show-summary>
          <el-table-column
            prop="companyName"
            header-align="center"
            align="center"
            label="公司名称"
          />
          <el-table-column
            prop="companyTotalRecharge"
            header-align="center"
            align="center"
            label="累积充值金额"
          />
          <el-table-column
            prop="openingAmount"
            header-align="center"
            sortable
            align="center"
            label="期初余额"
          />
          <el-table-column
            prop="closingAmount"
            header-align="center"
            sortable
            align="center"
            label="期末余额"
          />
          <el-table-column
            prop="accumulatedAmount"
            header-align="center"
            sortable
            align="center"
            label="充值金额"
          />
          <el-table-column
            prop="allocationAmount"
            header-align="center"
            sortable
            align="center"
            label="分配金额"
          />
        </el-table>
      </div>
    </el-tab-pane>
    <el-tab-pane label="充值明细" name="second">
      <div class="feature-form-container">
        <el-form
          :inline="true"
          :model="dataForm"
          ref="dataForm"
          class="bysearchForm"
        >
          <div class="searchLine">
            <el-form-item label="时间" class="typeInput">
              <el-date-picker
                v-model="time"
                class="el-popper DatepickerTime"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item class="typeInput">
              <el-select
                v-model="dataForm.companyIds"
                placeholder="公司"
                filterable
                multiple
                clearable
              >
                <el-option
                  v-for="(item, index) in companyList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-button size="mini" @click="getDataList()">查询</el-button>
            <el-button
              @click="exportCustomerAccount()"
              size="small"
              type="primary"
              >导出客户账户金额报表</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="table-container">
        <el-table :data="detailList" ref="detailList" border show-summary>
          <el-table-column
            prop="companyName"
            header-align="center"
            align="center"
            label="公司名称"
          />
          <el-table-column
            prop="changedMoney"
            header-align="center"
            align="center"
            label="充值金额"
          />
          <el-table-column
            prop="changedTime"
            header-align="center"
            align="center"
            label="充值时间"
          />
          <el-table-column
            prop="statusText"
            header-align="center"
            sortable
            align="center"
            label="审核状态"
          />
          <el-table-column
            prop="realPay"
            header-align="center"
            sortable
            align="center"
            label="实际充值金额"
          />
          <el-table-column
            prop="approveName"
            header-align="center"
            sortable
            align="center"
            label="审核人"
          />
        </el-table>
      </div>
    </el-tab-pane>
    <el-tab-pane label="分配明细" name="third">
      <div class="feature-form-container">
        <el-form
          :inline="true"
          :model="dataForm"
          ref="dataForm"
          class="bysearchForm"
        >
          <div class="searchLine">
            <el-form-item label="时间" class="typeInput">
              <el-date-picker
                v-model="time"
                class="el-popper DatepickerTime"
                type="daterange"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                clearable
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item class="typeInput">
              <el-select
                v-model="dataForm.companyIds"
                placeholder="公司"
                filterable
                multiple
                clearable
              >
                <el-option
                  v-for="(item, index) in companyList"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-button size="mini" @click="getDataList()">查询</el-button>
            <el-button
              @click="exportCustomerAccount()"
              size="small"
              type="primary"
              >导出客户账户金额报表</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="table-container">
        <el-table
          :data="serialList"
          ref="serialList"
          border
          stripe
          show-summary
          style="width: 100%"
        >
          <el-table-column
            prop="companyName"
            header-align="center"
            align="center"
            label="公司名称"
          />
          <el-table-column
            prop="subsidyMoney"
            header-align="center"
            align="center"
            label="分配金额"
          />
          <el-table-column
            prop="time"
            header-align="center"
            align="center"
            label="分配时间"
          />
          <el-table-column
            prop="statusText"
            header-align="center"
            align="center"
            label="分配状态"
          />
          <el-table-column
            prop="type"
            header-align="center"
            align="center"
            label="分配类型"
          />
        </el-table>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import moment from 'moment';
import { threeListMixin, normal } from '@/mixins';
import { getCompanyList } from '@/utils/options.js';
export default {
  mixins: [threeListMixin, normal],
  data() {
    return {
      activeName: 'first',
      dataForm: {
        dateRang: '',
        companyIds: [],
      },
      time: [],
      companyList: [],
      dataList: [],
      detailList: [],
      serialList: [],
    };
  },
  activated() {
    this.getDataList();
    this.getCompanyList();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },

    tableRowClassName({ row }) {
      // this.
      // if (row.isUse === 0) return 'danger-row';
    },
    //获取数据列表
    getDataList() {
      if (this.time?.length) {
        this.dataForm.dateRang = this.time.join(',');
      }
      this.$http({
        url: '/tc/order/customerAccount',
        method: 'get',
        params: {
          ...this.dataForm,
        },
      }).then(({ data }) => {
        if (data && data.status === 0) {
          this.dataList = data.companyAccountTotal;
          this.detailList = data.companyAccountRecharge;
          this.serialList = data.companyAccountAllocation;
        } else {
          this.dataList = [];
          this.detailList = [];
          this.serialList = [];
        }
      });
    },

    exportCustomerAccount() {
      if (this.payTime?.length) {
        this.dataForm.paidAtDatRang = this.payTime.join(',');
      }
      this.$http({
        url: `/tc/order/exportCustomerAccount`,
        method: 'post',
        params: {
          ...this.dataForm,
        },
        responseType: 'blob',
      }).then(({ data }) => {
        if (data && data.size > 0) {
          const content = data;
          const blob = new Blob([content], {
            // 下载的文件格式自己在这边更改type的值就好了
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          if ('download' in document.createElement('a')) {
            const elink = document.createElement('a');
            elink.download =
              '客户账户金额报表_-_' +
              moment(new Date()).format('YYYYMMDDHHmmss');
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
          } else {
            navigator.msSaveBlob(
              blob,
              '客户账户金额报表_-_' +
                moment(new Date()).format('YYYYMMDDHHmmss'),
            );
          }
        }
        console.log(data);
      });
    },

    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
  },
};
</script>

<style scoped></style>
